/* .venue-class div {
  cursor: default !important;
}  */

/* .scheduler-class table td {
  height: 142px;
} */
/* .scheduler-class{
  padding-left: 64px;
  margin-top: 25px;
} */

.video-player,
.live-player {
  height: 100% !important;
}

.prev_next_whole {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.votediv,
.contestantvotediv {
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-top: 20px;
}

.contestantcompvotediv {
  display: flex;
  flex-flow: row;
  align-items: center;
  /* color: white; */
}

.contestantcompvotediv img {
  width: 33px !important;
  height: 20px !important;
}

.profile-pic {
  height: 99px;
  width: 155px;
  margin-right: 120px;
}

.intro {
  flex: 1;
}

.contestantvotediv {
  padding-left: 23px;
}

.vote_img {
  padding-right: 10px;
}

.contestant_vote_img {
  padding-right: 10px;
  width: 25px !important;
  height: 15px !important;
  margin-bottom: 0px !important;
}

.prev_div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.img_text {
  color: white;
  text-align: center;
}

.prev_img {
  height: 32px;
  width: 32px;
}

.next_img {
  height: 32px;
  width: 32px;
  transform: rotate(178deg);
}

.separator {
  display: block;
  border-right: 1px solid #a8a8a8;
  padding-left: 20px;
  padding-right: 0px;
  margin-top: 78px;
  height: 100px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.schedule_container {
  width: 100%;
  position: relative;
}

.schedule_container::before {
  position: absolute;
  left: 50%;
  right: 50%;
  border-right: 5px dashed #c4c4c4;
  content: "";
  height: 100%;
}

.left_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.left_box {
  width: 475px;
  height: 248px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.left_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.left_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.right_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 78px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.right_box {
  width: 475px;
  height: 168px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.right_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.right_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.lastjul_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.lastjul_box {
  width: 475px;
  height: 298px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.lastjul_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.lastjul_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.sev_aug_div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.sev_aug_box {
  width: 475px;
  height: 168px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.sev_aug_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sev_aug_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.one_aug_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.one_aug_box {
  width: 475px;
  height: 496px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.one_aug_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.one_aug_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.jul_aug_col {
  padding: 0px !important;
}

.aug_sep_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.aug_sep_box {
  width: 475px;
  height: 456px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.aug_sep_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.aug_sep_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.sep_div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.sep_box {
  width: 475px;
  height: 201px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.sep_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sep_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.sixteen_aug_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.sixteen_aug_box {
  width: 475px;
  height: 288px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.sixteen_aug_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sixteen_aug_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.sep_nov_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.sep_nov_box {
  width: 475px;
  height: 361px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.sep_nov_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sep_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.fivesep_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.fivesep_box {
  width: 475px;
  height: 371px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.fivesep_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.fivesep_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.one_nov_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.one_nov_box {
  width: 475px;
  height: 201px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.one_nov_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.one_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.eight_nov_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.eight_nov_box {
  width: 475px;
  height: 201px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.eight_nov_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.eight_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.fourteen_nov_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.fourteen_nov_box {
  width: 475px;
  height: 247px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.fourteen_nov_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.fourteen_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.twenty_nov_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.twenty_nov_box {
  width: 475px;
  height: 201px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.twenty_nov_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.twenty_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.seven_dec_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.seven_dec_box {
  width: 475px;
  height: 247px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.seven_dec_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.seven_dec_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.eightteen_feb_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.eightteen_feb_box {
  width: 475px;
  height: 247px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.eightteen_feb_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.eightteen_feb_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.eighteen_dec_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.eighteen_dec_box {
  width: 475px;
  height: 201px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.eighteen_dec_connection {
  width: calc(100% - 475px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.eighteen_dec_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.purple_head {
  font-weight: bold !important;
  color: #b52fe6 !important;
  font-size: 20px !important;
  line-height: 25px !important;
}

.blue_head {
  font-weight: bold !important;
  color: #4e28f6 !important;
  font-size: 20px !important;
  line-height: 25px !important;
}

.green_head {
  font-weight: bold !important;
  color: #00dbac !important;
  font-size: 20px !important;
  line-height: 25px !important;
}

.pink_head {
  font-weight: bold !important;
  color: #ff05a3 !important;
  font-size: 20px !important;
  line-height: 25px !important;
}

.regis_txt {
  margin-bottom: 0px !important;
  font-size: 18px !important;
}

.bold_head {
  font-weight: bold !important;
}

.schedule_container {
  width: 100%;
  position: relative;
}

.schedule_container::before {
  position: absolute;
  left: 50%;
  right: 50%;
  border-right: 5px dashed #c4c4c4;
  content: "";
  height: 100%;
}

.left_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.left_box {
  width: 423px;
  height: 248px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.left_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.left_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.right_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 78px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.right_box {
  width: 427px;
  height: 168px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.right_connection {
  width: calc(100% - 427px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.right_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.lastjul_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.lastjul_box {
  width: 423px;
  height: 298px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.lastjul_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.lastjul_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.sev_aug_div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.sev_aug_box {
  width: 423px;
  height: 168px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.sev_aug_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sev_aug_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.one_aug_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.one_aug_box {
  width: 427px;
  height: 496px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.one_aug_connection {
  width: calc(100% - 422px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.one_aug_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.jul_aug_col {
  padding: 0px !important;
}

.aug_sep_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.aug_sep_box {
  width: 423px;
  height: 456px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.aug_sep_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.aug_sep_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.sep_div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.sep_box {
  width: 423px;
  height: 201px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.sep_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sep_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.sixteen_aug_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.sixteen_aug_box {
  width: 427px;
  height: 288px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.sixteen_aug_connection {
  width: calc(100% - 422px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sixteen_aug_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.sep_nov_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.sep_nov_box {
  width: 427px;
  height: 361px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.sep_nov_connection {
  width: calc(100% - 422px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.sep_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.fivesep_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.fivesep_box {
  width: 427px;
  height: 371px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.fivesep_connection {
  width: calc(100% - 422px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.fivesep_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.one_nov_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.one_nov_box {
  width: 423px;
  height: 201px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.one_nov_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.one_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.eight_nov_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.eight_nov_box {
  width: 427px;
  height: 201px;
  border: 3px solid #00dbac;
  border-radius: 10px;
}

.eight_nov_connection {
  width: calc(100% - 422px);
  position: relative;
  border: 1px solid #00dbac;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.eight_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #00dbac;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.fourteen_nov_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.fourteen_nov_box {
  width: 423px;
  height: 247px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.fourteen_nov_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.fourteen_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.twenty_nov_div {
  width: 50%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding-top: 30px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.twenty_nov_box {
  width: 427px;
  height: 201px;
  border: 3px solid #4e28f6;
  border-radius: 10px;
}

.twenty_nov_connection {
  width: calc(100% - 422px);
  position: relative;
  border: 1px solid #4e28f6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.twenty_nov_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #4e28f6;
  border-radius: 50%;
  left: -8px;
  border: 3px solid white;
}

.seven_dec_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.seven_dec_box {
  width: 423px;
  height: 247px;
  border: 3px solid #ff05a3;
  border-radius: 10px;
}

.seven_dec_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #ff05a3;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.seven_dec_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #ff05a3;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.eightteen_dec_col {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row;
  justify-content: center;
  padding: 0px !important;
}

.eightteen_dec_box {
  width: 423px;
  height: 247px;
  border: 3px solid #b52fe6;
  border-radius: 10px;
}

.eightteen_dec_connection {
  width: calc(100% - 423px);
  position: relative;
  border: 1px solid #b52fe6;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.eightteen_dec_connection::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: #b52fe6;
  border-radius: 50%;
  right: -14px;
  border: 3px solid white;
}

.regis_head {
  font-weight: bold !important;
  color: #b52fe6 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  padding-bottom: 10px;
}

.regis_txt {
  font-size: 18px !important;
}

.incompatible_div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  padding-top: 170px;
}

.no_prod_row {
  align-items: center;
  justify-content: center;
}

.trans_merchan_2 {
  font-size: 18px !important;
  line-height: 30px !important;
  color: black !important;
}

.oops_txt {
  font-size: 22px !important;
  line-height: 30px !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
}

.stream_txt {
  font-size: 18px !important;
  line-height: 30px !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader_show {
  position: relative;
}

.loader-pro {
  width: 100%;
  display: none;
}

.loader-pro-show {
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  position: absolute;
  background: white;
}
.loader-product-show {
  width: 100%;
  height: 100%;
  display: block;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: white;
}
.buttons-div-none {
  display: none;
}

.video_over_img {
  width: 100%;
  height: 100%;
  position: relative;
}

.video_over_cont {
  position: absolute;
}

.trans_merchan {
  font-size: 12px !important;
  color: black !important;
}

.radio_colummn {
  width: 70% !important;
  padding-right: 0px !important;
  padding-left: 2px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.approx_colummn {
  width: 30% !important;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
}

.image_div {
  height: 100px;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;
}

.spono_img {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
}

.consent_div {
  width: 100%;
  padding: 20px 0px;
}

.condition_div {
  padding: 20px 0px;
}
.base-label {
  display: flex;
  flex-flow: row;
  gap: 6px;
}
.base-label label::before {
  border-color: black !important;
  color: black !important;
}
.base-label path {
  fill: black !important;
}
.condition_ol {
  padding-left: 18px;
}
.condition_li {
  padding-left: 15px;
}
.link_condition {
  text-decoration: underline;
  color: #2b0030;
}
.consent_button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.buy_footer {
  font-style: italic;
  font-family: Rubik;
  color: #626262;
  font-size: 12px;
  line-height: 26px;
  font-weight: 100;
}

.span_mail {
  cursor: pointer;
  color: #5b5b5b;
  font-size: 14px;
  font-weight: bolder;
  display: block;
}

.popover_passcard {
  background: white;
  width: 191px;
  height: 120px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0 5px 3px -4px #777;
  -moz-box-shadow: 0 5px 3px -4px #777;
  box-shadow: 0 5px 3px -4px #777;
  font-weight: bold;
  color: #5f5f5f;
  word-break: break-word;
}

.popover_success_passcard {
  background: white;
  width: 191px;
  height: 85px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0 5px 3px -4px #777;
  -moz-box-shadow: 0 5px 3px -4px #777;
  box-shadow: 0 5px 3px -4px #777;
  font-weight: bold;
  color: #5f5f5f;
  word-break: break-word;
}

.ticket-column {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.stage_img > img {
  border-radius: 50%;
}

.img_inline {
  /* position: relative;
  display: inline;
  cursor: pointer; */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 600px;
  width: 97%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px !important;
}

.play_img_div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* top: 0; */
  position: relative;
}

.img_path {
  position: absolute;
}

.img_oval {
  position: absolute;
}

.feature-artists-class > div > *:nth-of-type(2) > div > div > div > div {
  display: flex;
  flex-flow: row;
  justify-content: center;
  text-align: center;
}

.feature-artists-class > div > *:nth-of-type(2) > div > div {
  width: 33.33% !important;
}

.sponsor-class {
  background: #f0f0f0;
}

.double-div {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  grid-column-gap: 8px;
  column-gap: 8px;
}

.ticket-detail {
  padding: 13px;
  display: flex;
  flex-flow: column;
  row-gap: 3px;
}

.ticket-head {
  font-size: 12px;
  font-weight: bold;
}

.img-div {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 8px;
}

.traveller-div {
  height: 130px;
  background-color: black;
  color: white;
  padding: 17px;
  font-size: 20px;
  font-weight: bolder;
  line-height: 25px;
}

.ticket-tier-card {
  min-width: 240px !important;
  width: 240px !important;
  height: 600px !important;
  border-radius: 20px !important;
  background: white !important;
}

.buttons-div {
  display: block;
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}

.paypal-label {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 20px;
}

.paypal-button-number-1 {
  display: none !important;
}

.hidden {
  display: none !important;
}
.concert {
  color: #585858 !important;
  font-size: 25px !important;
  font-weight: bold !important;
  line-height: 23px !important;
}

.jjlinText {
  color: black !important;
  font-size: 20px !important;
  font-weight: bold !important;
  padding-bottom: 15px;
  line-height: 23px !important;
}

.livestreamdiv {
  font-size: 15px;
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 6px;
  color: black;
  font-weight: bolder;
}

.synopsistext {
  font-size: 15px !important;
  color: black !important;
  font-weight: bolder !important;
  padding-bottom: 15px;
}

.livecont {
  padding-top: 10px;
}

.css-livestreamnow {
  background-color: white !important;
}

.powered_by_section {
  margin-left: 80px;
}

.spantag {
  position: relative;
  top: -7px;
}
.ptag {
  position: absolute;
  width: 200px;
  left: 173px;
  top: 23px;
  font-size: 16px;
  color: white;
}
.baselogo {
  display: none;
  width: 668px;
  cursor: pointer;
  height: 70px;
}
.moblogo {
  display: none;
  cursor: pointer;
}
.age_button,
.age_grey_btn {
  width: 289px !important;
  height: 46px !important;
}
.age_grey_btn,
.age_grey_btn:hover,
.age_grey_btn:active {
  background-color: #969696 !important;
}
.allow_section {
  display: flex;
  flex-direction: row;
  column-gap: 18px;
  row-gap: 26px;
}
.wrap_age {
  font-size: 24px !important;
  font-weight: bolder;
}
.style-p {
  font-size: 12px !important;
  line-height: 18px !important;
}

.contestant-p {
  font-size: 12px !important;
  line-height: 18px !important;
  margin-bottom: 5px !important;
}
.appFooter {
  background-color: #ffffff !important;
}
.stage_btn > span,
.feature_btn > span {
  font-weight: bolder !important;
}
.feature_btn {
  width: 400px !important;
  background-color: #f0f0f0 !important;
  border: 3px solid black !important;
  border-color: black !important;
  color: black !important;
}
.overview_body_cnt {
  background-color: #ffffff !important;
}
.css-carousel {
  justify-content: center;
  display: grid;
}

.css-watch-free,
.css-work-shop {
  justify-content: center;
  padding-right: 0 !important;
}
.feature-artists-class h6 {
  font-size: 14px !important;
}

.stage-class h5 {
  line-height: 25px !important;
}

#dropdown {
  height: 43px;
  width: 100%;
  margin-right: 15px;
  margin-bottom: 10px;
  margin-left: 12px;
  margin-top: 0px;
}

#dropdown input {
  max-width: 140px;
}

#dropdown_channel {
  height: 43px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  display: none;
}

#dropdown_channel input {
  max-width: 129px;
}

#dropdown-menu {
  top: 306px;
  left: 84.5px;
  width: 167.172px;
  border: 1px solid black;
}

#dropdown_channel-menu {
  top: 205px;
  left: 187.172px;
  width: 182.172px;
  border: 1px solid black;
}

.line-in-upcoming {
  width: 132px;
  height: 1px;
  float: left;
  fill: #000000;
  background: #5231fe;
  stroke-width: 1;
  display: block;
  /* margin-left: 25px; */
}

.line-class {
  padding: 10px 0 !important;
}

.upcoming-title {
  height: 28px;
  font-size: 18px !important;
  color: #585858 !important;
  text-decoration: none solid rgb(88, 88, 88);
  font-weight: 600 !important;
  margin-left: 9px !important;
  margin-top: 14px !important;
}

.buy_btn {
  width: 288px !important;
}

.success_img {
  width: 100%;
  height: 343px;
}

nav > ul > li > a {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #585858 !important;
}

nav > ul > li {
  width: 25% !important;
}

.nav_header {
  background: white !important;
}

body {
  background: white !important;
}

.tag_icon {
  display: none;
}

.category-class {
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
  width: 100%;
}

.category-class .active {
  background-color: #4d4d4d !important;
  color: #fff !important;
}

.category-class button {
  margin: 5px;
  height: 56px;
  border-radius: 10px !important;
  color: #000000;
  /* border-radius: 10px; */
  background: transparent;
  border: 2px solid #999999;
}

.SSO_header {
  /* height: 100px; */
  background-color: #f49fc8 !important;
}

.SSO_header img {
  /* width: 160px;
  height: 53px; */
  margin-top: 6px;
  margin-left: 20px;
  width: 466px;
  height: 74px;
}
/* .vodContainer {
  margin-top: 44px;
} */

.video_btn {
  width: 332px !important;
  height: 46px !important;
}

.fail_text {
  color: #fe0300 !important;
  font-size: 26px !important;
  line-height: 35px !important;
}

.fail_p {
  margin-bottom: 0px !important;
}

.success_txt,
.redeem_txt {
  font-weight: 500 !important;
  padding: 20px !important;
  line-height: 35px !important;
  padding-top: 0px !important;
}

.redeem_txt {
  line-height: 30px !important;
}

.thank_txt {
  font-weight: 100 !important;
}

.label_txt {
  font-weight: 500 !important;
}

.tranform_txt {
  text-transform: capitalize;
}

.synopissection h1,
.videoDetailFeature h1 {
  font-size: 48px !important;
}

.synopissection h2,
.videoDetailFeature h2 {
  font-size: 39px !important;
}

.synopissection h3,
.videoDetailFeature h3 {
  font-size: 30px !important;
}

.synopissection h4,
.videoDetailFeature h4 {
  font-size: 20px !important;
}

.synopissection h5,
.videoDetailFeature h5 {
  font-size: 18px !important;
}

.synopissection h6,
.videoDetailFeature h6 {
  font-size: 14px !important;
}

.synopsis {
  font-size: 14px !important;
  font-weight: bold !important;
  padding-bottom: 15px !important;
}

.festure-presenter {
  font-size: 14px !important;
  font-weight: bold !important;
}

.artist {
  line-height: 15px !important;
}

.scheduler-class table td div {
  border-bottom: 2px solid #fff;
}

.pdf-view {
  /* padding: 10px ; */
  margin: 16px 0px 16px 0px;
}

.pdf-view iframe {
  width: 884px !important;
  height: 884px !important;
}

.pdf-view a {
  width: 100% !important;
  margin-top: 20px;
}

.pdf-view .pdf-download {
  align-items: center;
  flex-direction: column;
  display: none !important;
}

.payment-section-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bannerImage {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin-top: 80px;
}

.bannerImageLap {
  display: none;
}

.bannerMobile {
  display: none;
}

.addedBorder {
  width: 60%;
  padding-top: 20px;
  border-bottom: 1px solid #d4d4d4;
}

.drop-in-payment {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dropdwon-scheduler {
  width: 160px;
  border: none;
  margin-left: 10px;
  height: 26px;
  padding: 0px;
  border-bottom: 1px solid #8c8c8c;
  background-color: rgb(255, 255, 248);
}

.channel-select {
  width: 160px;
  border: none;
  margin-left: 10px;
  height: 26px;
  padding: 0px;
  border-bottom: 1px solid #8c8c8c;
  background-color: rgb(255, 255, 248);
  display: none;
}

.schedule-title svg {
  margin-left: 10px;
  fill: lightgrey;
}

.main_image {
  width: 165px;
  height: 165px;
}

.pass_head {
  width: auto;
  line-height: 32px !important;
  font-size: 21px !important;
  font-weight: 500 !important;
}

.no_product_head {
  width: auto;
  line-height: 32px !important;
  font-size: 23px !important;
  font-weight: 500 !important;
}

.product_section {
  width: 100%;
  justify-content: space-between;
  flex-direction: row !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.info_section {
  width: 100%;
  display: inline-block;
  font-size: 11px;
  line-height: 17px;
  padding-top: 5px;
}

.confirm_section {
  width: 100%;
  display: inline-block;
  font-weight: 100 !important;
  line-height: 23px !important;
  padding-top: 7px;
}

.transaction_header {
  width: auto;
  line-height: 35px !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}

.now-playing-class {
  background: transparent;
  padding: 0 !important;
}

.now-playing-class svg {
  width: 18px;
  height: 18px;
  padding: 0px;
  position: relative;
  top: 5px;
}

.vod-class span {
  font-size: 15px !important;
}

.card-slides {
  box-shadow: none !important;
  max-width: 380px;
}

.card-slides img {
  display: block;
  width: 380px;
  height: 213px;
  margin-bottom: 10px;
  cursor: pointer;
}

.video-slides {
  box-shadow: none !important;
  /* margin-left: 5em!important; */
}

.video-ondemand-slides,
.contestant-slides {
  box-shadow: none !important;
}

.contestant-slides {
  padding: 7px !important;
}

.video-slides img,
.video-ondemand-slides img {
  height: 201px;
  width: 350px;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}

.contestant-slides img {
  height: 183px;
  width: 100%;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}

.title-tiles {
  height: 25px;
  font-size: 16px;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 22px;
  display: block;
  font-weight: bold;
  /* word-break: break-word; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 360px;
  cursor: pointer;
}

.vod-tiles {
  width: 340px;
}

.contestant-tiles {
  width: 283px;
}

.title-tiles-channel {
  font-size: 16px;
  color: #000000;
  text-decoration: rgb(0, 0, 0);
  line-height: 22px;
  display: block;
}

.stage-class .border_bottom {
  border: none;
}

.highlight-class {
  width: 100%;
}

.highlight-class .feature-artists-class {
  padding-top: 0px !important;
}

.highlight-class-color {
  background: #f0f0f0;
}

.ticket-tier-class {
  background-color: white;
  padding-bottom: 50px;
}

.video-class {
  padding-top: 10px !important;
  margin-bottom: 20px;
}

.video-class div {
  border: none;
}

.highlight-title {
  font-size: 25px;
  font-weight: 600;
  color: #000000;
  text-decoration: none solid rgb(0, 0, 0);
  margin-top: 18px;
}

.justify {
  text-align: justify;
}

.contestant-slides {
  border: 1px solid #d4d4d4 !important;
  border-radius: 5px !important;
  margin: 8px;
}

.container {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-top: 35px;
  padding-left: 40px;
  padding-right: 40px;
}

.contestantcontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-top: 35px;
}

.synopiscontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-left: 20px;
  padding-right: 45px;
}

.contestantsynopiscontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
  padding-left: 70px;
}

.bookcontainer {
  width: 1200px;
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  /* z-index: 9; */
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  background-color: #00254f;
}

.videoDetailFeature {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
  padding: 45px 5px 20px 0px;
}

.videoDetailFeature > h6 {
  text-transform: capitalize !important;
  font-size: 16px !important;
  /* line-height: 2px !important;
  font-weight: 500; */
}

.videoDetailFeature > p {
  text-transform: capitalize !important;
  margin-bottom: 10px !important;
}

/* .videoDetailFeature > div > div > div {
  flex-direction: column !important;
} */

.row {
  width: 100%;
  display: inline-block;
  position: relative;
  background-color: #fffef7;
}

.row::before {
  content: "";
  position: absolute;
  top: 0;
  background: #000000;
  left: 0;
  width: 100%;
  height: 500px;
}

.wholediv {
  /* height: 477px; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  border-top: 1px solid #616161;
}

.contestantswholediv {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  position: relative;
  /* border-top: 1px solid #616161; */
}

.contestantvoddiv {
  border-top: 1px solid #616161;
}

.synopisdiv {
  /* display: flex; */
  width: 100%;
}

.bookdiv {
  display: flex;
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  /* padding-top: 10px; */
  padding-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.titletext {
  line-height: 22px !important;
  margin-left: 31px;
  font-size: 16px !important;
  color: white !important;
  font-weight: 800 !important;
}

.videodiv {
  width: 70%;
  background-color: black;
  /* height: 100%;
  width: 100%; */
  height: 477px;
  /* min-height: 208px; */
  /* position: relative;
  padding-bottom: 41px; */
}

.secondscreen_div {
  height: 151px;
  width: 70%;
  background-color: black;
  border-top: 3px solid white;
  padding: 23px;
}

.fullsecond_screen {
  width: 279px !important;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 23px;
  border: none;
  background-color: rgb(0, 0, 0, 55%);
}

#scroll_div::-webkit-scrollbar {
  width: 5px;
}

#scroll_div::-webkit-scrollbar-thumb {
  background: #ff0000;
}

#scroll_div::-webkit-scrollbar-track {
  background: white;
  border-left: 3px solid transparent;
  background-clip: padding-box;
}

.frame_div {
  height: 465px;
}

.icon_div {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  visibility: visible;
}

.mob_icon_div {
  display: none;
}

.screen_title {
  color: white;
  font-size: 15px;
  font-weight: 800;
}

.close_div {
  height: 15px;
  display: none;
}

.arrow_div {
  height: 20px;
  display: none;
  transform: rotate(91deg);
}

.show {
  display: block;
}

.scroll_div {
  display: block;
  height: 100px;
  overflow-y: auto;
}

.fullscreen_show {
  display: block;
}

.fullscreen_close {
  display: none;
}

.content_div {
  color: white;
  padding: 15px 0px;
}

.thumb_div {
  display: flex;
  flex-flow: row;
  column-gap: 15px;
  align-items: center;
}

.img_div {
  width: 111px;
  height: 64px;
}

.thumb_cont {
  color: white;
}

.mobchatalt {
  height: 350px;
  background-color: white;
  display: none;
}

/* .videodiv > div > div > video {
  height: auto !important;
} */

.text_div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 9;
  color: white;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 25px;
  background-color: #151515de;
  padding-right: 330px;
  padding-bottom: 0px;
}

.text_div.disablechat {
  padding-right: 0px;
  padding-bottom: 0px;
}

.audience-header-back {
  visibility: hidden !important;
}

.disablechat {
  width: 100%;
}

.chatsection {
  width: 30%;
}

.synopissection {
  width: 100%;
  padding: 20px 20px 20px 20px;
  /* display: flex; */
}

.disablemoderator {
  width: 100%;
}

.booksection {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.booktext {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 15px;
}

.spread_section {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  text-decoration: none;
  color: black;
}

.wrap_div,
.wrap_age_div,
.wrap_explore_div {
  padding: 50px 0 0 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 9;
  font-size: 15px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  row-gap: 23px;
  line-height: 25px;
  background-color: #ffffff;
  color: black;
  border: 10px solid;
}

.wrap_age_div {
  padding: 86px 0 0 75px;
  row-gap: 30px;
}

.wrap_explore_div {
  padding: 170px 0 0 75px;
  row-gap: 24px;
}

.pass_div {
  width: 70%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.chatQAMenu {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* border-bottom: 1px solid; */
  padding: 20px 0px 10px 0px;
}

.chatMenuTitle {
  width: 50%;
  text-align: center;
}

.chatMenuTitleSpan {
  cursor: pointer;
  height: 25px;
  padding-bottom: 7px;
  padding-right: 40px;
  padding-left: 10px;
  color: #999999;
  font-size: 12px;
  font-weight: 800;
}

.chatMenuTitle:hover .chatMenuTitleSpan {
  /* border-bottom: 6px solid #02c09e; */
  color: #000000;
}

.chatMenuTitleImage {
  width: 15px;
  height: 14px;
  margin-left: -35px;
  margin-bottom: -3px;
  filter: brightness(0);
  -webkit-filter: brightness(0);
  -moz-filter: brightness(0);
  opacity: 0.4;
}

.QAimage {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 50px;
  text-align: center;
}

.chatQAMenuActive {
  /* border-bottom: 6px solid #02c09e; */
  color: #000000;
}

.chatMenuActive,
.chatMenuTitle:hover .chatMenuTitleImage {
  filter: brightness(1);
  -webkit-filter: brightness(1);
  -moz-filter: brightness(1);
  opacity: 1;
}

.wrap_title,
.age_title {
  color: black !important;
  width: 750px;
  font-size: 25px !important;
  text-align: center;
  line-height: 41px !important;
}
.explore_title {
  color: white !important;
  width: 100%;
  font-size: 28px !important;
  text-align: left;
  line-height: 41px !important;
}

.wrap_pass,
.wrap_age {
  color: black !important;
  margin-bottom: 10px;
  min-height: 50px;
  text-align: center;
}

.wrap_age {
  min-height: 33px;
}

.wrap_login {
  color: white !important;
  margin-bottom: 10px;
  text-align: left;
}

.group_pass_head {
  width: auto;
  line-height: 32px !important;
  font-size: 21px !important;
  font-weight: 500 !important;
}

.login_btn {
  width: 288px;
  height: 46px !important;
}

.button_row {
  width: 100%;
}

.group_pass_login_section {
  line-height: 30px !important;
}

.web_schedule {
  visibility: visible;
  margin-left: 35px;
}

.mob_schedule {
  display: none;
}

.voddiv > div > div > div > div > iframe {
  width: 100% !important;
  height: 100% !important;
}

.season_radio {
  line-height: 25px;
}

.pass_price {
  display: flex;
  justify-content: flex-end;
}

.addondiv,
.overlayaddondiv {
  width: 100% !important;
}

.addondiv {
  height: 425px !important;
}

.addon_title {
  padding: 30px 20px;
}

.addonimg {
  display: flex;
  flex-flow: row;
  column-gap: 10px;
  padding: 20px;
  align-items: center;
}

.border {
  width: 315px;
  border-bottom: 1px solid #c2c2c2;
  margin: 0px 20px;
  opacity: 0.2;
}

.menuborder {
  display: block;
  width: 315px;
  border-bottom: 1px solid #bdbdbd;
  margin: 0px 20px;
  opacity: 0.7;
}

.message {
  position: relative;
  z-index: 999;
}

.css-0 {
  position: relative;
  z-index: 999;
}

.css-gwpmru {
  position: relative;
  z-index: 9;
}

.contestants-card {
  flex: 1;
  max-width: 300px;
}

.show-live {
  width: 100%;
  height: 100%;
}

.show-live img {
  width: 100%;
  height: 100%;
}

.contestant-tile {
  width: 25%;
  display: flex;
  justify-content: center;
}

.performer_card {
  min-width: 214px !important;
  min-height: 273px !important;
  padding: 21px;
  border: 2px solid #d4d4d4 !important;
  border-radius: 10px !important;
}
.performer_card:hover {
  border: 2px solid #f49fc8 !important;
}

.performer_card img {
  width: 170px !important;
  height: 170px !important;
}
.performer_name {
  text-align: center;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}
.performer_role {
  text-align: center;
  margin-bottom: 0px !important;
}

.artistParentDiv {
  margin-top: 30px !important;
}
.modal_content {
  padding: 42px 44px 10px 35px !important ;
}
.modal_body {
  width: 1064px !important;
  border-radius: 0px !important;
  margin-top: 331px !important;
}
/* .descrip{
  margin-bottom: 0px !important;
} */
.bio_image {
  width: 130px !important;
}

.trailerDiv {
  width: 100%;
  height: 704px;
}

@media (min-width: 866px) {
  .highlight-class {
    padding-right: 191px;
    padding-left: 197px;
  }
  .sponsor-class {
    padding-right: 70px;
    padding-left: 140px;
  }

  .highlight-class-color,
  .ticket-tier-class {
    padding-right: 150px;
    padding-left: 165px;
  }
  .overview_body_cnt {
    padding-right: 150px !important;
    padding-left: 235px !important;
  }
  .stage_img {
    width: 30% !important;
  }
  .stage_cont {
    width: 70% !important;
  }

  .stage_btn {
    width: 336px !important;
  }
  .live-bdy {
    padding: 0px !important;
  }
}

.livestream_banner {
  cursor: pointer;
  height: 40px;
  background-color: #e3e3e3;
  display: flex;
  align-items: center;
}
.livestream_banner > p:first-child {
  margin-left: 127px;
}
.livestream_banner img:first-child {
  margin-bottom: 2px;
}
.watch_now {
  position: absolute;
  right: 0px;
  margin-right: 207px;
  color: #5231fe;
  font-weight: 800 !important;
}

.watch_now_arrow {
  position: absolute;
  right: 0px;
  margin-right: 190px;
}

.livestream_banner_tab {
  cursor: pointer;
  height: 62px;
  background-color: #e3e3e3;
  display: flex;
  align-items: center;
}

.livestream_banner_tab > div:first-child {
  margin-left: 127px;
}
@media (max-width: 768px) {
  .livestream_banner_tab > div:first-child {
    margin-left: 102px;
  }
}
@media (max-width: 767px) {
  .livestream_banner_tab > div:first-child {
    margin-left: 15px;
  }
  .watch_now {
    margin-right: 45px;
  }
  .watch_now_arrow {
    margin-right: 27px;
  }
}

.livestream_banner_mob {
  cursor: pointer;
  height: 82px;
  background-color: #e3e3e3;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .livestream_banner_mob > div:first-child {
    margin-left: 15px;
  }
}

@media (min-width: 1022px) {
  .livestream_banner_mob {
    display: none;
  }
  .livestream_banner_tab {
    display: none;
  }
}

@media (min-width: 485px) and (max-width: 1022px) {
  .livestream_banner_mob {
    display: none;
  }
  .livestream_banner {
    display: none;
  }
}
@media (max-width: 485px) {
  .livestream_banner_tab {
    display: none;
  }
  .livestream_banner {
    display: none;
  }
}

@media (min-width: 765px) and (max-width: 997px) {
  .highlight-class {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media (min-width: 768px) {
  .user_buypass,
  .baseuserpass {
    margin-right: 148px;
  }
  .main_image {
    width: 100%;
    height: auto;
  }
  .pass_head {
    width: auto;
    line-height: 32px;
  }
  .no_product_head {
    width: auto;
    line-height: 32px;
  }
  .product_section {
    width: 100%;
    justify-content: space-between;
  }
  .info_section {
    font-size: 12px;
  }
  .drop-in-payment {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
  .success_section {
    padding: "0px 0px 40px 40px";
  }
  .price_section_web {
    display: block;
    padding-left: 320px;
  }
  .price_section_mob {
    display: none;
    padding-left: 320px;
  }
  .bookcontainer {
    display: none;
  }
  .bookwebsection {
    display: block;
  }
  .bookmobsection {
    display: none;
  }
  .text_div {
    font-size: 32px;
    line-height: 42px;
  }
  .mike_img {
    /* width: 728px; */
    height: 90px;
    display: block;
  }
  .mike_mob_img {
    display: none;
  }
  .video-slides,
  .video-ondemand-slides {
    width: 380px !important;
    /* padding: 0 0 10px 10px !important; */
  }

  nav > ul > li:hover,
  nav > ul > li.active {
    /* border: none !important; */
    /* border-bottom: double 5px black !important; */
    border-bottom: 5px solid #5231fe !important;
    /* text-decoration-line: underline;
    text-decoration-style: double; */
  }
  nav > ul > li > a,
  nav > ul > li > a:hover,
  nav > ul > li > a.active {
    border: none !important;
    /* border-bottom: 8px solid #ff0200 !important; */
  }
  /* .SSO_Menu {
    padding-top: 30px;
  } */
}

@media only screen and (max-width: 620px) {
  .allow_section {
    flex-direction: column;
    row-gap: 26px;
    column-gap: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .img_text {
    font-size: 9px;
    line-height: 14px;
  }
  .baseuserpass {
    margin-top: 23px;
  }
  .logindiv {
    width: max-content;
    padding: 7px 10px;
  }
  .concert {
    font-size: 18px !important;
  }
  .jjlinText {
    font-size: 16px !important;
  }
  .img_inline {
    height: 200px;
  }
  .img_oval {
    width: 60px;
    height: 60px;
  }
  .img_path {
    width: 20px;
    height: 20px;
  }

  nav > ul > li > a:hover,
  nav > ul > li > a.active {
    border-top: 4px solid black !important;
  }
  .ptag {
    margin-left: -146px;
    margin-top: -6px;
    left: 196px;
  }
  .moblogo {
    display: block;
    padding-top: 5px;
    padding-left: 0px;
  }
  .pdf-view .pdf-download {
    display: flex !important;
  }
  .pdf-view iframe {
    display: none !important;
  }
  .SSO_header {
    width: 100% !important;
  }
  .SSO_header img {
    margin-top: 6px;
    margin-left: 20px;
    width: 312px;
    height: 50px;
  }
  .schedule_column {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #dropdown_123 {
    flex-direction: column !important;
    padding: 10px !important;
  }
  .video-slides img,
  .video-ondemand-slides img {
    height: 213px;
    width: 302px !important;
    display: block;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .group_pass_head {
    width: auto;
    font-size: 18px !important;
    line-height: 30px !important;
    padding-bottom: 24px;
  }
  #dropdown_channel {
    display: flex;
  }
  #dropdown {
    display: flex;
    margin-top: 0px;
  }
  .group_pass_login_section {
    line-height: 23px !important;
    font-size: 15px !important;
  }
  .button_row {
    padding-top: 20px;
  }
  .payment-section-text {
    display: block;
  }
  .payment-section-text h3 {
    font-size: 18px;
    color: #000000;
    text-decoration: rgb(0, 0, 0);
  }
  .braintree-sheet {
    width: 95%;
  }
  .css-xq2s6o {
    width: 95%;
  }
  .drop-in-payment {
    padding: 15px;
  }
  .try_again_btn {
    width: 100%;
  }
  .transaction_header {
    font-size: 20px !important;
    line-height: 27px !important;
  }
  .price_section_web {
    display: none;
    padding-left: 320px;
  }
  .price_section_mob {
    display: block;
    padding-left: 320px;
  }

  .failed_txt {
    font-size: 14px !important;
  }

  .confirm_section {
    line-height: 21px !important;
    padding-top: 20px !important;
  }

  /* .row {
    margin-top: 80px;
  } */

  .row::before {
    background: black;
    height: 250px;
  }

  .bookcontainer {
    width: 100%;
    display: block;
  }
  .title {
    display: flex;
    align-items: center;
    padding-top: 75px;
    padding-left: 17px;
    padding-bottom: 15px;
  }
  .wholediv {
    flex-direction: column;
    border: none;
  }
  .contestantswholediv {
    border: none;
  }

  .synopisdiv {
    flex-direction: column;
  }

  .bookdiv {
    flex-direction: column;
  }

  .videodiv {
    width: 100%;
    height: auto;
    min-height: 208px;
    position: relative;
    /* padding-bottom: 41px; */
  }
  .frame_div {
    width: 100%;
    height: 100%;
  }
  .text_div {
    padding-right: 0px;
    padding-bottom: 465px;
  }
  .text_div.disablechat {
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .synopissection {
    width: 100%;
    padding: 24px 15px 5px 14px;
  }

  .chatsection {
    width: 100%;
  }

  .bookwebsection {
    display: none;
  }

  .bookmobsection {
    display: block;
  }

  .wrap_div {
    padding: 30px 25px 0 25px;
    row-gap: 20px;
  }
  .wrap_age_div {
    padding: 77px 25px 0 25px;
    row-gap: 20px;
  }
  .wrap_explore_div {
    padding: 75px 25px 0 42px;
    row-gap: 27px;
  }

  .wrap_title,
  .explore_title,
  .age_title {
    width: 100%;
    line-height: 28px !important;
    font-size: 20px !important;
  }

  .wrap_pass,
  .wrap_login {
    font-size: 17px !important;
    height: auto;
    min-height: auto;
  }

  .wrap_age {
    font-size: 22px !important;
  }

  .mobchatalt {
    display: block;
  }

  .login_btn {
    width: 100%;
  }

  .channel-select {
    display: block;
  }

  .web_schedule {
    display: none;
  }

  .mob_schedule {
    display: block;
    padding-bottom: 100px;
    padding-left: 15px;
  }

  .card-slides {
    padding: 0px !important;
  }

  .thumb_div {
    flex-flow: column;
    row-gap: 15px;
    align-items: flex-start;
  }
  .secondscreen_div {
    width: 279px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 12px 23px;
    border: none;
    background-color: rgb(0, 0, 0, 55%);
  }
  .mob_icon_div {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    visibility: visible;
  }
  .icon_div {
    display: none;
  }
  .scroll_div {
    display: none;
  }
  .scorll_show {
    display: block;
  }
  .tag_icon {
    display: block;
    width: 15px;
    height: 15px;
    filter: brightness(0);
    -moz-filter: brightness(0);
    -webkit-filter: brightness(0);
    opacity: 0.7;
  }
  a.active .tag_icon {
    filter: brightness(1);
    -moz-filter: brightness(1);
    -webkit-filter: brightness(1);
    opacity: 1;
  }
  .pass_div {
    width: 100%;
    row-gap: 22px;
    flex-flow: column;
  }
  nav > ul {
    width: 100% !important;
  }
  nav > ul > li {
    width: 25% !important;
  }
  nav > ul > li > a {
    font-size: 8px !important;
    padding: 0px !important;
  }
  .buy_btn {
    width: 100% !important;
  }
  .success_img {
    height: auto;
  }
  .highlight-title {
    font-size: 23px !important;
    line-height: 27px !important;
  }
  .videoDetailFeature {
    padding: 0px 15px 20px 13px;
  }
  .title {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 380px) {
  .card-slides img,
  .video-slides img,
  .video-ondemand-slides img,
  .contestant-slides img {
    width: 100%;
  }
  .title-tiles {
    width: 300px;
  }
  .video_btn {
    width: 100% !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .category-class {
    display: contents !important;
  }
  .live-grid {
    width: 100% !important;
    margin: 0 auto;
  }
  .live-bdy {
    padding: 0px !important;
  }
  .stage_img {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

@media (min-width: 320px) and (max-width: 553px) {
  .bannerMobile {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 553px) {
  .bannerImageLap {
    display: block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 320px) and (max-width: 765px) {
  .bannerImage {
    margin-top: 40px;
  }
  .bannerImage img {
    margin-top: 22px;
  }
  .addedBorder {
    width: 100%;
  }
  .overlayaddondiv {
    height: 300px !important;
  }
  .appFooter {
    height: 80px !important;
  }
  .contestant-tile {
    width: 50%;
  }
}

@media only screen and (max-width: 359px) {
  #dropdown_123 {
    flex-direction: column !important;
    padding: 10px !important;
  }
  #dropdown_channel {
    margin-left: 12px;
  }
}
@media (min-width: 575px) {
  .css-watch-free {
    /* padding-left: 132px !important; */
    padding-top: 60px !important;
  }
  #dropdown_123 {
    flex-direction: row !important;
    padding: 10px !important;
  }
  .css-livenow {
    padding-left: 132px !important;
    padding-top: 60px !important;
  }

  .scheduler-class {
    margin-left: -64px !important;
  }
  .css-carousel {
    padding-top: 0px;
  }
  .upcoming-title {
    margin-top: 60px !important;
  }
}
@media (max-width: 575px) {
  .trailerDiv {
    height: 29.78vh;
    width: 93.5vw;
  }
  .text_div {
    padding-top: 74px;
    position: absolute;
    align-items: flex-start;
  }

  .video_over_img {
    top: 0;
    position: absolute;
    height: 200px;
  }
  .contestant-tile {
    width: 100%;
  }
  .contestant-tile {
    padding-left: 20px;
    padding-right: 20px;
  }
  .videoDiv {
    margin-left: 0px !important;
  }
  .synopsismob {
    padding-left: 0px !important;
  }
  .image_section {
    display: block !important;
    margin-left: 39px;
  }
  .profile-pic {
    height: auto;
  }
  .contestantcompvotediv {
    position: relative;
  }
  .wholediv {
    height: 205px;
  }
  .to-mob {
    margin-top: 0px !important;
  }
  #dropdown_channel {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  #dropdown {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}
@media (min-width: 866px) {
  .css-watch-free {
    padding-left: 148px !important;
    padding-right: 106px !important;
    padding-top: 72px !important;
  }
  .css-work-shop {
    padding-left: 148px !important;
    padding-right: 106px !important;
  }
  .css-livenow {
    padding-left: 128px !important;
    padding-right: 150px !important;
    padding-top: 41px !important;
  }
  .css-livestreamnow {
    padding-left: 200px !important;
    padding-right: 200px !important;
    padding-top: 40px !important;
  }
  .upcoming-title {
    margin-top: 26px !important;
  }
  .scheduler-class {
    margin-left: 20px !important;
  }
}
@media (max-width: 766px) {
  .css-watch-free {
    padding-top: 14px;
    /* margin-left: 31px; */
  }
  .css-livenow,
  .css-livestreamnow {
    padding-left: 11px;
    padding-right: 11px;
  }

  .contestants_div {
    width: 23% !important;
    margin-left: 15px !important;
  }
  /* .css-livestreamnow {
    width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
  } */
}
@media (min-width: 400px) and (max-width: 520px) {
  .video-class {
    height: 270px !important;
  }
}

@media (min-width: 521px) and (max-width: 1200px) {
  .video-class {
    height: 360px !important;
  }
}

@media (min-width: 425px) {
  .loginsecdiv {
    margin-left: 117px;
  }
}

@media (max-width: 425px) {
  .popovercontent {
    display: none;
  }
  .popover_passcard,
  .popover_success_passcard {
    height: 200px;
  }
  .video-class {
    margin-top: 35px !important;
    margin-bottom: 28px;
  }
  .stage-class h5 {
    font-size: 16px !important;
  }
  .stage_btn {
    width: 100% !important;
  }
  .stage_btn > span {
    font-size: 16px !important;
  }
  .feature_btn {
    width: 100% !important;
    padding: 10px !important;
  }
  .css-watch-free,
  .css-work-shop {
    padding-left: 0px !important;
  }
  /* .watchfree-tiles {
    width: 100%;
  } */
}

@media (max-width: 763px) {
  .to-mob {
    margin-top: 158px;
  }
}

@media (min-width: 765px) and (max-width: 805px) {
  .baselogo {
    width: 300px;
    height: 50px;
  }
  .contestant-tile {
    width: 25%;
  }
}
@media (min-width: 805px) and (max-width: 905px) {
  .baselogo {
    width: 320px;
    height: 58px;
  }
}
@media (min-width: 765px) {
  .baselogo {
    display: block;
  }
  .contestant-tile {
    width: 25%;
  }
}
@media (min-width: 765px) and (max-width: 1000px) {
  .contestant-tile {
    width: 33.3%;
  }
  .menu_bar {
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
  .css-watch-free {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .contestant-slides {
    min-width: 0px !important;
    min-height: auto !important;
  }
  .overview_body_cnt {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .css-work-shop {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .title {
    padding-top: 10px;
  }
  .synopiscontainer {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .row::before {
    height: 315px !important;
  }
  .videodiv {
    height: 289px;
  }

  /* .voddiv{
    height: 461px !important;
  }
  .synopiscontainer{
    padding-left: 34px !important;
  } */
}
@media (min-width: 768px) and (max-width: 1024px) {
  .img_inline {
    height: 300px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .img_mobile {
    display: block;
  }
  .img_laptop {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .img_mobile {
    display: none !important;
  }
  .img_laptop {
    display: block;
  }
}

@media (min-width: 1400px) {
  .video-class {
    height: 680px !important;
  }
}

@media (max-width: 1200px) {
  .votediv {
    padding-left: 10px;
  }
  .container,
  .contestantcontainer {
    width: 100%;
    padding: 0px;
  }
  .synopiscontainer {
    width: 100%;
    padding: 0px;
  }
  .contestantsynopiscontainer {
    width: 100%;
    padding-left: 40px;
  }
  .mike_mob_img {
    width: 100%;
    height: 50px;
    display: block;
  }

  .mike_img {
    display: none;
  }

  .schedule_img {
    width: 100%;
  }

  .separator {
    display: none;
  }

  .spon_col {
    display: flex;
    flex-flow: row;
    justify-content: center;
    text-align: center;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .trailerDiv {
    width: 100%;
    height: 343px;
  }
}
