.Loadercenter { 
    height: 500px;
     margin:0;
     padding:0;
   }
.Loadercenter .loaderContent{
     margin: 0;
     position: absolute;
     top: 50%;
     left: 50%;
     -ms-transform: translate(-50%, -50%);
     transform: translate(-50%, -50%);
   }
   .LoadingText{
    display:block;
    padding-bottom:10px;
    font-weight:700;
    font-size:18px;
   }
.spinner {
    margin: 5px auto 0;
    width: 70px;
    text-align: center;
  }
  .loaderContainer{
    margin-top: 100px;
    text-align: center;
}
  .loaderImg {
    background-image: url(./assets/images/loadingImage.png);
    background-repeat: no-repeat;
    background-size: 180px;
    width: 180px;
    height: 180px;
    border: 2px solid;
    border-radius: 100%;
  }
  
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    margin-right: 5px;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    margin-right: 5px;
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {

    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }